import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../Layout';
import '../../styles/BlogPost.css';
import TableOfContents from './TableOfContents';
import { fetchPostContent, processMarkdownContent } from '../../services/blogService';
import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-docker';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-toml';
import 'prismjs/components/prism-hcl';


export default function BlogPost() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [toc, setToc] = useState([]);
  const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;

  useEffect(() => {
    const loadPost = async () => {
      try {
        const { markdown, postInfo, release } = await fetchPostContent(BUCKET_URL, slug);
        const { content, tocItems } = processMarkdownContent(markdown, BUCKET_URL, release);

        setToc(tocItems);
        setPost({
          content,
          metadata: {
            ...postInfo,
            image_url: postInfo.image ? `${BUCKET_URL}/releases/${release}${postInfo.image}` : null
          }
        });
      } catch (error) {
        console.error('Failed to load post:', error);
      } finally {
        setLoading(false);
      }
    };

    loadPost();
  }, [slug, BUCKET_URL]);

  useEffect(() => {
    if (post) {
      Prism.highlightAll();
    }
  }, [post]);

  const scrollToHeading = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (loading) return <Layout><div>Loading...</div></Layout>;
  if (!post) return <Layout><div>Post not found</div></Layout>;

  return (
    <Layout>
      <div className="blog-post-container">
        <TableOfContents toc={toc} onHeadingClick={scrollToHeading} />
        <article className="blog-post">
          {post.metadata.image_url && (
            <div className="blog-post-image">
              <img src={post.metadata.image_url} alt={post.metadata.title} />
            </div>
          )}
          <h1>{post.metadata.title}</h1>
          <time>{post.metadata.date}</time>
          <div 
            className="markdown-content"
            dangerouslySetInnerHTML={{ __html: post.content }} 
          />
        </article>
      </div>
    </Layout>
  );
}